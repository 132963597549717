.team-wrap {
    .card {
        i.facebook {
            color: #3b5998;
            -webkit-text-fill-color: #3b5998 !important;
        }
        i.twitter {
            color: #3cf;
            -webkit-text-fill-color: #3cf !important;
        }
        i.google {
            color: #dc4a38;
            -webkit-text-fill-color: #dc4a38 !important;
        }
    }
}