/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-default .sb-wrapper {
  color: white;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--button-color) !important;
}
.sb-default .sb-wrapper.sb-facebook {
  background-color: #4267B2;
}
.sb-default .sb-wrapper.sb-twitter {
  background-color: #00acee;
}
.sb-default .sb-wrapper.sb-google {
  background-color: #db4437;
}
.sb-default .sb-wrapper.sb-mix {
  background-color: #ff8226;
}
.sb-default .sb-wrapper.sb-line {
  background-color: #00b900;
}
.sb-default .sb-wrapper.sb-linkedin {
  background-color: #006fa6;
}
.sb-default .sb-wrapper.sb-pinterest {
  background-color: #bd081c;
}
.sb-default .sb-wrapper.sb-reddit {
  background-color: #ff4006;
}
.sb-default .sb-wrapper.sb-tumblr {
  background-color: #36465d;
}
.sb-default .sb-wrapper.sb-whatsapp {
  background-color: #25d366;
}
.sb-default .sb-wrapper.sb-messenger {
  background-color: #0080FF;
}
.sb-default .sb-wrapper.sb-telegram {
  background-color: #0088cc;
}
.sb-default .sb-wrapper.sb-xing {
  background-color: #006567;
}
.sb-default .sb-wrapper.sb-sms {
  background-color: #20c16c;
}
.sb-default .sb-wrapper.sb-email {
  background-color: #FF961C;
}
.sb-default .sb-wrapper.sb-viber {
  background-color: #665ca7;
}
.sb-default .sb-wrapper.sb-vk {
  background-color: #4C75A3;
}
.sb-default .sb-wrapper.sb-copy {
  background-color: #607D8B;
}
.sb-default .sb-wrapper.sb-print {
  background-color: #765AA2;
}
.sb-default .sb-wrapper.sb-expand {
  background-color: #FF6651;
}
.sb-default .sb-wrapper.sb-show-icon.sb-show-text .sb-text {
  filter: none;
  padding-right: 1em;
  padding-left: 0;
}

/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-material-dark .sb-wrapper {
  background-color: #2E4057;
  background-color: var(--button-color) !important;
  font-family: "Roboto", sans-serif;
  color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.sb-material-dark .sb-wrapper.sb-facebook {
  background-color: #4267B2;
}
.sb-material-dark .sb-wrapper.sb-twitter {
  background-color: #00acee;
}
.sb-material-dark .sb-wrapper.sb-google {
  background-color: #db4437;
}
.sb-material-dark .sb-wrapper.sb-mix {
  background-color: #ff8226;
}
.sb-material-dark .sb-wrapper.sb-line {
  background-color: #00b900;
}
.sb-material-dark .sb-wrapper.sb-linkedin {
  background-color: #006fa6;
}
.sb-material-dark .sb-wrapper.sb-pinterest {
  background-color: #bd081c;
}
.sb-material-dark .sb-wrapper.sb-reddit {
  background-color: #ff4006;
}
.sb-material-dark .sb-wrapper.sb-tumblr {
  background-color: #36465d;
}
.sb-material-dark .sb-wrapper.sb-whatsapp {
  background-color: #25d366;
}
.sb-material-dark .sb-wrapper.sb-messenger {
  background-color: #0080FF;
}
.sb-material-dark .sb-wrapper.sb-telegram {
  background-color: #0088cc;
}
.sb-material-dark .sb-wrapper.sb-xing {
  background-color: #006567;
}
.sb-material-dark .sb-wrapper.sb-sms {
  background-color: #20c16c;
}
.sb-material-dark .sb-wrapper.sb-email {
  background-color: #FF961C;
}
.sb-material-dark .sb-wrapper.sb-viber {
  background-color: #665ca7;
}
.sb-material-dark .sb-wrapper.sb-vk {
  background-color: #4C75A3;
}
.sb-material-dark .sb-wrapper.sb-copy {
  background-color: #607D8B;
}
.sb-material-dark .sb-wrapper.sb-print {
  background-color: #765AA2;
}
.sb-material-dark .sb-wrapper.sb-expand {
  background-color: #FF6651;
}
.sb-material-dark .sb-wrapper:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.sb-material-dark .sb-wrapper.sb-show-icon.sb-show-text {
  padding-left: 0.3em;
}
.sb-material-dark .sb-wrapper.sb-show-icon.sb-show-text .sb-text {
  padding-right: 1em;
  padding-left: 0;
}

/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-modern-light .sb-wrapper {
  color: var(--button-color) !important;
  background-color: #fff;
  transition: box-shadow ease-in 150ms, transform ease-in 150ms;
  border-radius: 3px;
  box-shadow: 0 0.1em 0.5em rgba(165, 165, 165, 0.6);
}
.sb-modern-light .sb-wrapper.sb-facebook {
  color: #4267B2;
}
.sb-modern-light .sb-wrapper.sb-twitter {
  color: #00acee;
}
.sb-modern-light .sb-wrapper.sb-google {
  color: #db4437;
}
.sb-modern-light .sb-wrapper.sb-mix {
  color: #ff8226;
}
.sb-modern-light .sb-wrapper.sb-line {
  color: #00b900;
}
.sb-modern-light .sb-wrapper.sb-linkedin {
  color: #006fa6;
}
.sb-modern-light .sb-wrapper.sb-pinterest {
  color: #bd081c;
}
.sb-modern-light .sb-wrapper.sb-reddit {
  color: #ff4006;
}
.sb-modern-light .sb-wrapper.sb-tumblr {
  color: #36465d;
}
.sb-modern-light .sb-wrapper.sb-whatsapp {
  color: #25d366;
}
.sb-modern-light .sb-wrapper.sb-messenger {
  color: #0080FF;
}
.sb-modern-light .sb-wrapper.sb-telegram {
  color: #0088cc;
}
.sb-modern-light .sb-wrapper.sb-xing {
  color: #006567;
}
.sb-modern-light .sb-wrapper.sb-sms {
  color: #20c16c;
}
.sb-modern-light .sb-wrapper.sb-email {
  color: #FF961C;
}
.sb-modern-light .sb-wrapper.sb-viber {
  color: #665ca7;
}
.sb-modern-light .sb-wrapper.sb-vk {
  color: #4C75A3;
}
.sb-modern-light .sb-wrapper.sb-copy {
  color: #607D8B;
}
.sb-modern-light .sb-wrapper.sb-print {
  color: #765AA2;
}
.sb-modern-light .sb-wrapper.sb-expand {
  color: #FF6651;
}
.sb-modern-light .sb-wrapper:active {
  box-shadow: 0 0.4em 1em rgba(165, 165, 165, 0.9);
}
.sb-modern-light .sb-wrapper .sb-icon {
  min-width: 2em;
}
.sb-modern-light .sb-wrapper.sb-show-text {
  padding: 0;
}
.sb-modern-light .sb-wrapper.sb-show-text .sb-icon {
  box-shadow: 1px 0 1px -1px rgba(0, 0, 0, 0.3);
}

/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-circles-dark .sb-wrapper {
  color: #fff;
  background: transparent;
  min-width: unset;
  height: unset;
}
.sb-circles-dark .sb-wrapper.sb-facebook .sb-icon {
  background-color: #4267B2;
}
.sb-circles-dark .sb-wrapper.sb-facebook .sb-text {
  color: #4267B2;
}
.sb-circles-dark .sb-wrapper.sb-twitter .sb-icon {
  background-color: #00acee;
}
.sb-circles-dark .sb-wrapper.sb-twitter .sb-text {
  color: #00acee;
}
.sb-circles-dark .sb-wrapper.sb-google .sb-icon {
  background-color: #db4437;
}
.sb-circles-dark .sb-wrapper.sb-google .sb-text {
  color: #db4437;
}
.sb-circles-dark .sb-wrapper.sb-mix .sb-icon {
  background-color: #ff8226;
}
.sb-circles-dark .sb-wrapper.sb-mix .sb-text {
  color: #ff8226;
}
.sb-circles-dark .sb-wrapper.sb-line .sb-icon {
  background-color: #00b900;
}
.sb-circles-dark .sb-wrapper.sb-line .sb-text {
  color: #00b900;
}
.sb-circles-dark .sb-wrapper.sb-linkedin .sb-icon {
  background-color: #006fa6;
}
.sb-circles-dark .sb-wrapper.sb-linkedin .sb-text {
  color: #006fa6;
}
.sb-circles-dark .sb-wrapper.sb-pinterest .sb-icon {
  background-color: #bd081c;
}
.sb-circles-dark .sb-wrapper.sb-pinterest .sb-text {
  color: #bd081c;
}
.sb-circles-dark .sb-wrapper.sb-reddit .sb-icon {
  background-color: #ff4006;
}
.sb-circles-dark .sb-wrapper.sb-reddit .sb-text {
  color: #ff4006;
}
.sb-circles-dark .sb-wrapper.sb-tumblr .sb-icon {
  background-color: #36465d;
}
.sb-circles-dark .sb-wrapper.sb-tumblr .sb-text {
  color: #36465d;
}
.sb-circles-dark .sb-wrapper.sb-whatsapp .sb-icon {
  background-color: #25d366;
}
.sb-circles-dark .sb-wrapper.sb-whatsapp .sb-text {
  color: #25d366;
}
.sb-circles-dark .sb-wrapper.sb-messenger .sb-icon {
  background-color: #0080FF;
}
.sb-circles-dark .sb-wrapper.sb-messenger .sb-text {
  color: #0080FF;
}
.sb-circles-dark .sb-wrapper.sb-telegram .sb-icon {
  background-color: #0088cc;
}
.sb-circles-dark .sb-wrapper.sb-telegram .sb-text {
  color: #0088cc;
}
.sb-circles-dark .sb-wrapper.sb-xing .sb-icon {
  background-color: #006567;
}
.sb-circles-dark .sb-wrapper.sb-xing .sb-text {
  color: #006567;
}
.sb-circles-dark .sb-wrapper.sb-sms .sb-icon {
  background-color: #20c16c;
}
.sb-circles-dark .sb-wrapper.sb-sms .sb-text {
  color: #20c16c;
}
.sb-circles-dark .sb-wrapper.sb-email .sb-icon {
  background-color: #FF961C;
}
.sb-circles-dark .sb-wrapper.sb-email .sb-text {
  color: #FF961C;
}
.sb-circles-dark .sb-wrapper.sb-viber .sb-icon {
  background-color: #665ca7;
}
.sb-circles-dark .sb-wrapper.sb-viber .sb-text {
  color: #665ca7;
}
.sb-circles-dark .sb-wrapper.sb-vk .sb-icon {
  background-color: #4C75A3;
}
.sb-circles-dark .sb-wrapper.sb-vk .sb-text {
  color: #4C75A3;
}
.sb-circles-dark .sb-wrapper.sb-copy .sb-icon {
  background-color: #607D8B;
}
.sb-circles-dark .sb-wrapper.sb-copy .sb-text {
  color: #607D8B;
}
.sb-circles-dark .sb-wrapper.sb-print .sb-icon {
  background-color: #765AA2;
}
.sb-circles-dark .sb-wrapper.sb-print .sb-text {
  color: #765AA2;
}
.sb-circles-dark .sb-wrapper.sb-expand .sb-icon {
  background-color: #FF6651;
}
.sb-circles-dark .sb-wrapper.sb-expand .sb-text {
  color: #FF6651;
}
.sb-circles-dark .sb-wrapper:active .sb-icon {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.sb-circles-dark .sb-wrapper .sb-icon {
  background-color: #2E4057;
  background-color: var(--button-color) !important;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  font-size: 1.4em;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.sb-circles-dark .sb-wrapper .sb-text {
  color: var(--button-color) !important;
  font-size: 0.8em;
  margin-top: 5px;
  border-radius: 1em;
  width: 100%;
  height: 1.8em;
  line-height: 1.8em;
  padding: 0;
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.sb-circles-dark .sb-wrapper .sb-content {
  align-items: center;
  flex-direction: column;
}