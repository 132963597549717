@import "functions/functions";
@import "mixins/mixins";
@import "colors";
@import "palette";
// @import "../../vendor/animate.scss/animate.scss";
@import "animations";
@import "typography";
@import "margins";
@import "paddings";
@import "height-width";
@import "box-shadow";
@import "components/components";
@import "header";
@import "footer";
@import "utilities/utilities";
@import "views/views";
body {
  line-height: 1.5;
  font-size: 15px;
  // color: $body-text-color;
  color: $body-color;
}

.app-landing-wrap.landing_wrap {
  overflow: hidden;
}

.o-hidden {
  overflow: hidden;
}

.t-shadow {
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.22);
}

.btn {
  transition: all 0.5s ease-in;
}

.section-header p {
  max-width: 600px;
  margin-bottom: 0px !important;
}

.section-header h2 {
  /* line-height: 1; */
  margin-top: -0.4rem;
}

.light-gray {
  background: #f8f9fa !important;
}

.bg-primary-gradient {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#21c8f6),
    to(#637bff)
  );
  background-image: linear-gradient(180deg, #21c8f6, #637bff);
}

.text-gradient {
  color: $transparent-gradient;
}

.eva {
  color: rgb(71, 64, 79);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-warning {
  color: #ffc107 !important;
  -webkit-text-fill-color: #ffc107 !important;
}

.btn {
  transition: all 0.5s ease-in 0s;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
  transition-delay: 0s;
}

.btn-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.btn-hover:before {
  position: absolute;
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098d1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.btn-hover:focus:before,
.btn-hover:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.font-weight-medium {
  font-weight: 600 !important;
}

.landing_wrap .card-title {
  margin-bottom: 0.5rem;
  font-size: 18px;
}

[tabindex="-1"]:focus {
  outline: none;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 0;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

div {
  box-sizing: border-box;
}

html,
body {
  &[dir="rtl"],
  &[dir="ltr"] {
    unicode-bidi: embed;
  }
}

bdo[dir="rtl"] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir="ltr"] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

img {
  max-width: 100%;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

blockquote {
  border-left: 2px solid $gray-200;
  padding-left: 1rem;
  margin-bottom: 1rem;
  font-size: 1.01625rem;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  outline: none;
}

.o-hidden {
  overflow: hidden;
}

.separator-breadcrumb {
  margin-bottom: 2rem;
}

@for $i from 1 through 5 {
  .line-height-#{$i} {
    line-height: #{$i};
  }
}

.app-inro-circle {
  text-align: center;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: calc(50% - 150px);
  .big-bubble {
    height: 280px;
    width: 280px;
    margin: 0 auto 20px;
    text-align: center;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 108px;
      color: #ffffff;
    }
  }
}

.loadscreen {
  text-align: center;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  height: 100vh;
  background: #ffffff;
  z-index: 9999;
  .loader {
    position: absolute;
    top: calc(50vh - 50px);
    left: 0;
    right: 0;
    margin: auto;
  }
  .logo {
    display: inline-block !important;
    width: 80px;
    height: 80px;
  }
}

.img-preview {
  overflow: hidden;
  float: left;
  background-color: $gray-200;
  width: 100%;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.preview-lg {
  width: 200px;
  height: 150px;
}

.preview-md {
  width: 150px;
  height: 120px;
}

.preview-sm {
  width: 100px;
  height: 75px;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.half-button {
  border-radius: 3px 15px;
}

@media only screen and (max-width: 764px) {
  section#intro-wrap {
    padding-top: 120px;
  }
  .customizer.show {
    height: 500px;
    overflow-y: scroll;
    top: 120px;
    .setting_wrap {
      width: 40px;
      float: left;
      height: 40px;
      background: #fff;
      margin-right: 0px;
      line-height: 48px;
      text-align: center;
      font-size: 22px;
      border-radius: 5px;
      cursor: pointer;
      padding-left: 5px;
    }
  }
}

@media (max-width: 576px) {
  .app-inro-circle {
    .big-bubble {
      width: 220px;
      height: 220px;
    }
  }
  button.close {
    float: right;
    font-size: 1.2195rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    position: absolute;
    top: 0;
    right: 5px;
  }
}

[dir="rtl"] {
  .rtl-ps-none {
    .ps__rail-x,
    .ps__rail-y {
      display: none;
    }
  }
}
