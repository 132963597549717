.best-wrap {
    .card {
        .card-body {
            .left_icon {
                i {
                    font-size: 130px;
                    outline: none;
                    position: absolute;
                    left: -7%;
                    top: 6%;
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    opacity: .8;
                }
            }
        }
    }
}