.blog-date {
    position: absolute;
    /* top: 0; */
    bottom: 0px;
    /* left: 0; */
    right: 30px;
    display: grid;
    text-align: center;
    background-color: aliceblue;
    padding: 3px 12px;
    border-radius: 8px;
    height: 60px;
    width: 60px;
}

.news-three__card {
    height: 100%;
}

.news-three__card {
    height: 100%;
    .card-header {
        height: 300px;
        position: relative;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}