  @for $i from 0 through 10 {
    [t-m-#{$i}],
    .t-m-#{$i} {
      margin: #{$i}rem;
    }
  }
  
  @for $i from 0 through 10 {
    [t-mt-#{$i}],
    .t-mt-#{$i} {
      margin-top: #{$i}rem;
    }
  }
  @for $i from 0 through 10 {
    [t-mr-#{$i}],
    .t-mr-#{$i} {
      margin-right: #{$i}rem;
    }
  }
  @for $i from 0 through 10 {
    [t-mb-#{$i}],
    .t-mb-#{$i} {
      margin-bottom: #{$i}rem;
    }
  }
  @for $i from 0 through 10 {
    [t-ml-#{$i}],
    .t-ml-#{$i} {
      margin-left: #{$i}rem;
    }
  }
  
  .t-m-05,
  [t-m-05] {
    margin: 0.5rem !important;
  }
  .t-mt-05,
  [t-mt-05] {
    margin-top: .5rem !important;
  }
  .t-mr-05,
  [t-mr-05] {
    margin-right: .5rem !important;
  }
  .t-ml-05,
  [t-ml-05] {
    margin-left: .5rem !important;
  }
  .t-mb-05,
  [t-mb-05] {
    margin-bottom: .5rem !important;
  }
  .t-m-033,
  [t-m-033] {
    margin: .333333rem !important;
  }