.intro-nine-Wrap {
  padding: 180px 0px 40px;
  color: #fff;
  overflow: hidden;
  position: relative;
  z-index: 99;
  .intro-nine-RightSection {
    position: relative;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 1;
  }
  &:after {
    content: "";
    display: block;
    height: 100%;
    background: url("/assets/images/landing/Bg/Bg_08.png") bottom no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    z-index: -1;
  }
}

@media only screen and (max-width: 992px) {
  .intro-nine-Wrap {
    .intro-nine-LeftSection {
      margin-bottom: 0px;
    }
  }
}
