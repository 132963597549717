.intro2Wrap {
  background: url("/assets/images/landing/Bg/bg.jpeg") center center/cover
    no-repeat;
  padding: 180px 0;
  color: #fff;
  overflow: hidden;
  position: relative;
  z-index: 99;
  .intro2Description {
    max-width: 600px;
    margin: 0 auto;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.95;
    //  background: $transparent-gradient;
  }
}

.intro2Wrap:after {
  content: "";
  display: block;
  height: 100%;
  background: url("/assets/images/landing/Bg/Bg_06.png") bottom no-repeat;
  background-size: cover;
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  z-index: -1;
}
