
    i.ul-tab__icon{
        font-size: 14px;
        font-weight: 500;
    }

    .ul-tab__border{
        border:1px dashed $gray-500;
        margin: 30px 0;
    }

    .ul-tab__content{
        margin: 0;
        padding-left: 0;
    }
    .ul-dropdown__toggle{
        padding: 8px 25px;
        margin: 0 5px;
    }
   
